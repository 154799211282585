import { type Market } from '@bridebook/toolbox/src/gazetteer';

export const getSeasonDurationInMonths = (season: string, market: Market): number => {
  const result = market.mappings?.seasons?.[season];

  if (result != null) {
    return result.length;
  }

  /**
   * @TODO: [i18n] We still don't have season mappings for all markets.
   * This is a temporary backwards-compatible solution to make sure we don't break the app.
   */
  return 3;
};
