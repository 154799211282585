import { FelaCSS } from '../../../../components/fela/flowtypes';
import colorParse from '../../../../fela-utils/color-parse';
import { colors } from '../../../../themes/variables';
import { SharedTypes } from './a';

type StyleTypes = {
  wrapper: FelaCSS;
};

const styles = ({ noUnderline, bold = true, color }: SharedTypes): StyleTypes => {
  const fontColor = color ? colorParse(color) : colors.indigoCrush;

  const interaction: FelaCSS = {
    textDecoration: noUnderline ? 'none' : 'underline',
    color: fontColor,
  };

  return {
    wrapper: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      ...(bold ? { fontDefaultSemiBold: 'inherit' } : { fontDefault: 'inherit' }),
      color: fontColor,

      ':hover': {
        ...interaction,
      },

      ':active': {
        ...interaction,
      },

      ':focus': {
        ...interaction,
      },
    },
  };
};

export default styles;
