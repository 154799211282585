import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

export const COUNTRY_SEPARATOR = '--';

export const addCountryToSearchUrl = (baseUrl: string, country?: CountryCodes) => {
  if (!baseUrl || !country) {
    return baseUrl;
  }

  const [url, query] = baseUrl.split('?');

  /**
   * Check if URL already has country code.
   */
  if (url.toLowerCase().endsWith(`${COUNTRY_SEPARATOR}${country}`.toLowerCase())) {
    return baseUrl;
  }

  /**
   * For UK, we don't suffix the country code.
   */
  if (country.toUpperCase() === CountryCodes.GB) {
    return baseUrl;
  }

  return `${url}${COUNTRY_SEPARATOR}${country.toLowerCase()}${query ? '?' + query : ''}`;
};
