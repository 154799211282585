/**
 * Default fields to be included for the Place in the details response when the details are successfully retrieved.
 * This omits Contact and Atmosphere data fields, which incur in additional costs.
 *
 * See https://developers.google.com/maps/documentation/places/web-service/place-data-fields#basic.
 */
export const basicFields = [
  'address_component',
  'adr_address',
  'business_status',
  'formatted_address',
  'geometry.viewport',
  'geometry.location',
  'icon',
  'icon_mask_base_uri',
  'icon_background_color',
  'name',
  'photos',
  'place_id',
  'plus_code',
  'type',
  'url',
  'utc_offset_minutes',
  'vicinity',
];
