import { format as dateFnsFormat } from 'date-fns';
import { de, enGB, enIE, enUS, fr } from 'date-fns/locale';
import { getI18n } from 'lib/i18n/getI18n';
import { Locales } from '../constants';

const localeToDateLocale: Record<Locales, unknown> = {
  [Locales.DE]: de,
  [Locales.FR]: fr,
  [Locales.UK]: enGB,
  [Locales.IE]: enIE,
};

/**
 * Returns date-fns language files based on the current next-i18next locale
 */
export const getDateFnsLocale = () => {
  const language = getI18n().language;

  const dateLocaleFromRouting = localeToDateLocale[language as Locales];

  if (dateLocaleFromRouting) {
    return dateLocaleFromRouting;
  }

  switch (language) {
    case 'de-DE':
    case 'de-AT':
      return de;
    case 'fr-FR':
      return fr;
    case 'en-GB':
      return enGB;
    case 'en-US':
    default:
      return enUS;
  }
};

/**
 * Wrapper over date-fns format, which has localisation options passed
 */
export const format: typeof dateFnsFormat = (date, format, options) =>
  dateFnsFormat(date, format, {
    locale: getDateFnsLocale(),
    ...options,
  });
