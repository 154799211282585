import i18next, { i18n as I18NextClient } from 'i18next';
import * as nextI18next from 'next-i18next';

let cachedInstance: I18NextClient | null = null;

export const getI18n = (i18nInstanceToDeriveLangFrom?: I18NextClient): I18NextClient => {
  const properI18nInstance = nextI18next.i18n as I18NextClient | null;

  if (!properI18nInstance) {
    if (!cachedInstance) {
      cachedInstance =
        cachedInstance ||
        i18next.createInstance(
          i18nInstanceToDeriveLangFrom
            ? {
                lng: i18nInstanceToDeriveLangFrom.options.lng,
                supportedLngs: i18nInstanceToDeriveLangFrom.options.supportedLngs,
              }
            : undefined,
        );
      cachedInstance.init();
    }

    return cachedInstance;
  }

  return properI18nInstance;
};
