import { CountryCodes, type Market } from '@bridebook/toolbox/src/gazetteer';

export const getSeasonMonth = (season: string, market: Market): number => {
  const result = market.mappings?.seasons?.[season]?.at(0);

  if (result != null) {
    return result;
  }

  /**
   * @TODO: [i18n] We still don't have season mappings for all markets.
   * This is a temporary backwards-compatible solution to make sure we don't break the app.
   */
  const isUK = market.country === CountryCodes.GB;

  switch (season) {
    case 'summer':
      // Summer in UK is June and in the rest of the world it is July
      return isUK ? 5 : 6;
    case 'autumn':
      // Autumn in UK is September and in the rest of the world it is October
      return isUK ? 8 : 9;
    case 'winter':
      // Winter in UK is December and in the rest of the world it is January
      return isUK ? 11 : 0;
    case 'spring':
      // Spring in UK is March and in the rest of the world it is April
      return isUK ? 2 : 3;
    default:
      throw new Error('There is no such season!');
  }
};
