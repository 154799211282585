import { mergeDeepRight } from 'ramda';
import React, { HTMLProps, ReactNode } from 'react';
import Box from '../../../../components/fela/Box';
import { FelaCSS } from '../../../../components/fela/flowtypes';
import { IStylingProps, TColor } from '../../../../themes/types';
import componentStyles from './a.style';

export interface SharedTypes extends HTMLProps<ReactNode | Element> {
  noUnderline?: boolean;
  bold?: boolean;
  color?: TColor;
  style?: FelaCSS;
}

interface IProps extends SharedTypes, IStylingProps {
  href?: string;
  dataTest?: string;
}

export type AnchorProps = Omit<IProps, 'ref'>;

const A = React.forwardRef<unknown, IProps>(
  ({ noUnderline, bold, children, color, style = {}, ...restProps }, ref) => {
    const styles = componentStyles({ noUnderline, bold, color });

    return (
      <Box as="a" style={mergeDeepRight(styles.wrapper, style)} setRef={ref} {...restProps}>
        {children}
      </Box>
    );
  },
);

export default A;
