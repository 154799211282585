import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';

// Added to make sure that these venue types do not show on the Popular Venue Types carousel
// for non-core markets
const defaultExcludedTypes = new Set([
  'cityHotelCityVenue',
  'museumAttraction',
  'sportingVenueStadium',
  'statelyHome',
  'townHallRegistryOffice',
  'warehouseFactory',
  'otherVenueType',
]);

// TODO: [i18n][bb-global] What about other markets?
export const countriesExcludedTypes: Record<string, Set<string>> = {
  [CountryCodes.GB]: new Set(['resort', 'uniqueVenueType']),
  [CountryCodes.DE]: new Set(['placeOfWorship', 'resort', 'statelyHome', 'uniqueVenueType']),
  [CountryCodes.FR]: new Set([
    'bestOfBritain',
    'cityHotelCityVenue',
    'gardenOutdoor',
    'golfCourse',
    'museumAttraction',
    'otherVenueType',
    'outdoorCeremonyLicence',
    'resort',
    'sportingVenueStadium',
    'statelyHome',
    'warehouseFactory',
    'weddingLicence',
  ]),
  [CountryCodes.IE]: new Set([
    'cityHotelCityVenue',
    'cruiseBoatYacht',
    'gardenOutdoor',
    'golfCourse',
    'museumAttraction',
    'otherVenueType',
    'placeOfWorship',
    'sportingVenueStadium',
    'resort',
    'statelyHome',
    'townHallRegistryOffice',
    'warehouseFactory',
  ]),
  [CountryCodes.US]: new Set(['resort', 'uniqueVenueType']),
  [CountryCodes.CA]: defaultExcludedTypes,
  [CountryCodes.AU]: defaultExcludedTypes,
};

export const culturalSpecialistOptions = {
  asianWeddingSpecialist: 'asian-weddings',
  jewishWeddingSpecialist: 'jewish-weddings',
  muslimWeddingSpecialist: 'muslim-weddings',
  otherWeddingSpecialist: 'other-weddings',
  notApplicable: 'not-applicable',
};
