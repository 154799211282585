import { getMonth, setMonth } from 'date-fns';
import { getSeasonDurationInMonths } from '@bridebook/toolbox/src/datepicker/get-season-duration-in-months';
import { getSeasonMonth } from '@bridebook/toolbox/src/datepicker/get-season-month';
import { type Market } from '@bridebook/toolbox/src/gazetteer';

const createMonthsArray = (startMonth: number, seasonDurationInMonths: number) =>
  Array.from({ length: seasonDurationInMonths }, (_, i) =>
    // setting the month and then getting it to
    // translate december + any number correctly into months
    // e.g. 11 (december) + 1 month is 0 (january) and not 12
    getMonth(setMonth(new Date(), startMonth + i)),
  );
export const getSeasonInMonths = (season: string, market: Market): number[] => {
  const months = market.mappings?.seasons?.[season];

  if (months != null) {
    return months;
  }

  /**
   * @TODO: [i18n] We still don't have season mappings for all markets.
   * This is a temporary backwards-compatible solution to make sure we don't break the app.
   */
  const firstMonthOfSeason = getSeasonMonth(season, market);
  const seasonDuration = getSeasonDurationInMonths(season, market);

  return createMonthsArray(firstMonthOfSeason, seasonDuration);
};
